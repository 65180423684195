<!-- @format -->

<template>
  <div>
    <!-- faq search section -->
    <section id="faq-search-filter">
      <b-card
          no-body
          class="faq-search"
          :style="{
          backgroundImage: `url(${require('@/assets/images/banner/banner.png')})`,
        }"
      >
        <b-card-body class="text-center"
                     style="height:130px;padding: 0!important;display: flex; align-items: center; justify-content: center;"
        >
          <h2 class="text-primary"/>
          <b-card-text class="mb-2"/>

          <!-- form -->
          <b-form
              class="faq-search-input"
              style="display: flex; align-items: center; justify-content: center;"
              @submit.prevent
          >
            <b-dropdown id="dropdown-1" :text="value" class="m-md-2" style="margin-left: 0!important;">
              <b-dropdown-item
                  v-for="(categoryObj, categoryName) in faqData"
                  :key="categoryName"
                  @click="changeTab(categoryName)"
              >{{ categoryObj.title }}
              </b-dropdown-item
              >
            </b-dropdown>
            <b-input-group class="input-group-merge" style="margin: 0">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon"/>
              </b-input-group-prepend>
              <b-form-input
                  id="searchbar"
                  v-model="faqSearchQuery"
                  placeholder="Search"
                  @keyup.enter="keyEnter"
              />
            </b-input-group>
          </b-form>
          <!-- form -->
        </b-card-body>
      </b-card>
    </section>
    <!--/ faq search section -->

    <!-- frequently asked questions tabs pills -->
    <section id="faq-tabs">
      <faq-question-answer
          :options="fetchContent"
          :cate_id="cate_id"
          :title="faqSearchQuery"
          :key="new Date().getTime()"
      />
    </section>
    <!--/ frequently asked questions tabs pills -->

    <!--/ contact us -->
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BForm,
  BInputGroup,
  BFormInput,
  BCardText,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BImg,
  BRow,
  BCol,
  BAvatar,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import axiosIns from '@/libs/axios'
import FaqQuestionAnswer from './FaqQuestionAnswer.vue'

export default {
  components: {
    BForm,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BImg,
    FaqQuestionAnswer,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      tabsIndex: 0,
      faqSearchQuery: '',
      cate_id: 5,
      faqData: [],
      fetchContent: [],
      value: 'All',
    }
  },
  watch: {
    faqSearchQuery: {
      immediate: true,
      handler() {
        // this.fetchDetails();
        // console.log(this.faqData);
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.fetchData()
    })
  },
  methods: {
    keyEnter() {
      this.fetchDetails()
    },
    changedd(val) {
      // 设置鼠标忙碌
      this.fetchContent = []
      this.cate_id = this.faqData[val].id
      this.fetchDetails()
    },
    changeTab(index) {
      this.cate_id = this.faqData[index].id
      this.value = this.faqData[index].title
      this.fetchDetails()
    },
    fetchData() {
      axiosIns.get('/article/getArticleCateList', { params: { pageSize: 30 } }) // 添加 pageSize 参数
        .then((res) => {
            this.faqData = [{
                id: 10086,
                title: 'All'
            }]
            let cate_list = [...this.faqData, ...res.data.list]
            this.faqData = []

            const includeIds = [6, 7, 8, 9, 23, 24, 25, 26, 27, 28, 29,0,10086] // 定义需要包含的 id

            cate_list.forEach(item => {
                if (includeIds.includes(item.id)) {
                    this.faqData.push(item)
                }
            })

            this.cate_id = this.faqData[0].id
            this.value = this.faqData[0].title
            this.fetchDetails()
        })


    },
    fetchDetails() {
      axiosIns
          .get(
              `/article/list?cate_id=${this.cate_id}&title=${this.faqSearchQuery}&pageSize=100`
          )
          .then((res) => {
            this.fetchContent = []
            res.data.list.forEach(item => {
              if (item.cate_id !== 1 && item.cate_id !== 2 && item.cate_id !== 3 && item.cate_id !== 11 && item.cate_id !== 12) {
                this.fetchContent.push(item)
              }
            })
            // this.fetchContent = res.data.list
          })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-faq.scss";
</style>
